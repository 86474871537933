import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import _ from 'lodash';
import Footer from '../layouts/Footer';

const data = [
  {
    title: 'First Image',
    imgSrc: 'https://cdn.sixt.io/cms/originals/03201357-db6f-4687-842f-1ede6eaf626a.jpg',
  },
  {
    title: 'Second Image',
    imgSrc: 'https://cdn.sixt.io/cms/originals/03201357-db6f-4687-842f-1ede6eaf626a.jpg',
  },
  {
    title: 'Third Image',
    imgSrc: 'https://cdn.sixt.io/cms/originals/03201357-db6f-4687-842f-1ede6eaf626a.jpg',
  },
  {
    title: 'Fourth Image',
    imgSrc: 'https://cdn.sixt.io/cms/originals/03201357-db6f-4687-842f-1ede6eaf626a.jpg',
  },
  {
    title: 'Fift Image',
    imgSrc: 'https://cdn.sixt.io/cms/originals/03201357-db6f-4687-842f-1ede6eaf626a.jpg',
  },
  {
    title: 'Sixt Image',
    imgSrc: 'https://cdn.sixt.io/cms/originals/03201357-db6f-4687-842f-1ede6eaf626a.jpg',
  },
  {
    title: 'Seventh Image',
    imgSrc: 'https://cdn.sixt.io/cms/originals/03201357-db6f-4687-842f-1ede6eaf626a.jpg',
  },
  {
    title: 'Eight Image',
    imgSrc: 'https://cdn.sixt.io/cms/originals/03201357-db6f-4687-842f-1ede6eaf626a.jpg',
  },
];

export default function AboutPage() {
  const [text, setText] = useState('');
  const [t, i18n] = useTranslation('common');

  const [fullText, setFullText] = useState(`${t('welcome.menuAboutUs')}`);
  const [index, setIndex] = useState(0);

  const [slideLeft, setSlideLeft] = useState(0);
  const [hideButtonLeft, setHideButtonLeft] = useState(true);
  const [hideButtonRight, setHideButtonRight] = useState(false);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [count, setCount] = useState(1);

  useEffect(() => {
    if (index < fullText.length) {
      setTimeout(() => {
        setText(text + fullText[index]);
        setIndex(index + 1);
      }, 40);
    }
  }, [index]);

  useEffect(() => {
    setText(`${t('welcome.menuAboutUs')}`);
  }, [i18n.language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // console.log("offsetWidth" , document.getElementById('hscroll').offsetWidth)
    // console.log("clientWidth" , document.getElementById('hscroll').clientWidth)
    // console.log("scrollWidth" , document.getElementById('hscroll').scrollWidth)
    setSliderWidth(document.getElementById('hscroll').scrollWidth - document.getElementById('hscroll').offsetWidth);
  }, []);

  const moveRight = () => {
    const el = document.getElementById(`hscroll`);
    setSlideLeft((el.scrollLeft += 200));
  };

  const moveLeft = () => {
    const el = document.getElementById(`hscroll`);
    setSlideLeft((el.scrollLeft -= 200));
  };

  const onHScroll = () => {
    const el = document.getElementById(`hscroll`).scrollLeft;
    /* eslint-disable */
    if (0 < el && el < 148) {
      setCount(1);
    }
    if (149 < el && el < 300) {
      setCount(2);
    }
    if (301 < el && el < 444) {
      setCount(3);
    }
    if (el > 0) {
      setHideButtonLeft(false);
    } else {
      setHideButtonLeft(true);
    }
    if (el < sliderWidth) {
      setHideButtonRight(false);
    } else {
      setHideButtonRight(true);
    }
    /* eslint-enable */
    console.log(count);
  };

  return (
    <>
      <div className="relative" id="overlayBack">
        <main className="flex-grow">
          <div>
            <div className="py-5 bg-white">
              <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
                <div className="relative">
                  <h1 className="title-font font-extralight text-gray-900 text-1xl text-center sm:text-4xl md:text-4xl ">
                    {text}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center">
            <img
              src="/AboutPageImage.jpg"
              className="p-1 bg-white object-cover h-3/5 w-3/5 lg:h-2/5 lg:w-2/5"
              alt="Mrg About Page"
            />
          </div>

          <div className="py-5 bg-white">
            <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
              <div className="relative">
                <div className="w-3/4 flex items-center lg:w-3/5 mx-auto flex-row flex-col">
                  <img src="/mrg2.png" className="w-28 object-cover object-right-top" alt="mrg" />
                </div>

                <div className="hidden md:block w-full my-4">
                  <div className="w-full">
                    <p className="w-full text-lg text-center text-gray-500">{t(`welcome.aboutTitle1`)}</p>
                  </div>
                </div>

                <p className="w-full py-8 mx-auto -mt-2 text-base text-justify text-gray-500 intro sm:max-w-3xl md:hidden">
                  {t(`welcome.aboutTitle1`)}
                </p>
              </div>
            </div>
          </div>
          <div className="py-10 bg-white hidden md:block">
            <div className="text-gray-600 body-font">
              <div className="px-5 py-2 mx-auto max-w-7xl">
                <div className="">
                  <Slider
                    dots={false}
                    slidesToShow={3}
                    slidesToScroll={1}
                    autoplay={false}
                    responsive={[
                      {
                        breakpoint: 600,
                        settings: {
                          className: 'center',
                          centerMode: true,
                          centerPadding: '15px',
                          slidesToShow: 1.1,
                        },
                      },
                    ]}
                  >
                    <div className="p-4 md:w-1/3">
                      <div className="h-60 md:h-[360px] lg:h-[250px] border border-gray-100 rounded-xl shadow-lg bg-white overflow-hidden">
                        {/* <img
                          className="lg:h-48 md:h-36 w-full object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100"
                          src="/Hand.png"
                          alt="mrg"
                        /> */}
                        <div className="p-6">
                          <h1 className="title-font text-lg font-medium text-center text-gray-600 mb-3">
                            {t(`welcome.ourValue`)}
                          </h1>
                          <p className="leading-relaxed text-[15px] text-center mb-3">
                            <ul className="">
                              <li>{t(`welcome.integrity`)}</li>
                              <li>{t(`welcome.teamWork`)}</li>
                              <li>{t(`welcome.safety`)}</li>
                              <li>{t(`welcome.quality`)}</li>
                              <li>{t(`welcome.customerLoyalty`)}</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="p-4 md:w-1/3">
                      <div className="h-60 md:h-[360px] lg:h-[250px] border border-gray-100 rounded-xl shadow-lg bg-white overflow-hidden">
                        {/* <img
                          className="lg:h-48 md:h-36 w-full object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100"
                          src="/target.png"
                          alt="mrg"
                        /> */}
                        <div className="p-6">
                          <h1 className="title-font text-lg font-medium text-center text-gray-600 mb-3">
                            {t(`welcome.ourVision`)}
                          </h1>
                          <p className="leading-relaxed text-[14px] text-center mb-3">{t(`welcome.ourVisionDesc`)}</p>
                        </div>
                      </div>
                    </div>
                    <div className="p-4 md:w-1/3">
                      <div className="h-60 md:h-[360px] lg:h-[250px] border border-gray-100 rounded-xl shadow-lg bg-white overflow-hidden">
                        {/* <img
                          className="lg:h-48 md:h-36 w-full object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100"
                          src="/Rocket.png"
                          alt="mrg"
                        /> */}
                        <div className="p-6">
                          <h1 className="title-font text-lg font-medium text-center text-gray-600 mb-3">
                            {' '}
                            {t(`welcome.ourMission`)}
                          </h1>
                          <p className="leading-relaxed text-[15px] text-center mb-3">{t(`welcome.ourMissionDesc`)}</p>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="py-24 bg-white md:hidden ">
            <h1 className="pr-5 title-font text-lg text-right font-medium text-gray-600 mb-5">{count}/3</h1>

            <div className="flex flex-col bg-white m-auto p-auto">
              <div className="flex overflow-x-scroll pb-10 hide-scroll-bar" id="hscroll" onScroll={() => onHScroll()}>
                <div className="flex flex-nowrap lg:ml-40 pr-16 md:ml-20 ml-10 ">
                  <div className="inline-block px-3">
                    <div className="w-64 h-[400px]  max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                      {/* <img
                          className="lg:h-48 md:h-36 w-full object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100"
                          src="/Hand.png"
                          alt="mrg"
                        /> */}
                      <div className="p-6">
                        <h1 className="title-font text-lg font-medium text-center text-gray-600 mb-3">
                          {t(`welcome.ourValue`)}
                        </h1>
                        <ul className="leading-relaxed text-[15px] mb-3">
                          <li className="py-1">{t(`welcome.integrity`)}</li>
                          <li>{t(`welcome.teamWork`)}</li>
                          <li>{t(`welcome.safety`)}</li>
                          <li>{t(`welcome.quality`)}</li>
                          <li>{t(`welcome.customerLoyalty`)}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="inline-block px-3">
                    <div className="w-64 h-[400px] max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                      {/* <img
                          className="lg:h-48 md:h-36 w-full object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100"
                          src="/Hand.png"
                          alt="mrg"
                        /> */}
                      <div className="p-6 ">
                        <h1 className="title-font text-lg font-medium text-center text-gray-600 mb-3">
                          {t(`welcome.ourVision`)}
                        </h1>
                        <p className="leading-relaxed text-[15px] mb-3">{t(`welcome.ourVisionDesc`)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="inline-block px-3">
                    <div className="w-64 h-[400px] max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                      {/* <img
                          className="lg:h-48 md:h-36 w-full object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100"
                          src="/Hand.png"
                          alt="mrg"
                        /> */}
                      <div className="p-6 ">
                        <h1 className="title-font text-lg font-medium text-center text-gray-600 mb-3">
                          {t(`welcome.ourMission`)}
                        </h1>
                        <p className="leading-relaxed text-[15px] mb-3">{t(`welcome.ourMissionDesc`)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center items-center">
            <div className="max-w-7xl">
              <div className="w-full flex justify-center items-center">
                <div className="overflow-hidden">
                  <div className="container">
                    <div className="flex flex-wrap justify-between items-center -mx-4 ">
                      <div className="w-full h-5/5 lg:w-6/12 px-1">
                        <div className="flex items-center justify-center -mx-3 sm:-mx-4">
                          <img
                            src="/AboutLast1.jpeg"
                            className="w-full max-sm:w-5/6 md:w-7/12 xl:w-4/5 xl:h-3/5 sm:px-4"
                            alt=""
                          />
                          {/* <div className="w-full xl:w-1/2 px-3 sm:px-4">
                        <div className="py-3 sm:py-4">
                          <img src="/mrg14.png" alt="" className="rounded-3xl w-5/6" />
                        </div>
                        <div className="py-3 sm:py-4">
                          <img src="ing1.png" alt="" className="rounded-2xl w-6/6" />
                        </div>
                      </div>
                      <div className="w-full xl:w-1/2 px-3 sm:px-4">
                        <div className="my-4 relative z-10">
                          <img src="core4.png" alt="" className="rounded-2xl w-full" />
                         
                        </div>
                      </div> */}
                        </div>
                      </div>
                      <div className="w-full lg:w-1/2 xl:w-5/12 px-8 py-24">
                        <div className="mt-10 lg:mt-0">
                          <span className="font-semibold text-lg text-primary mb-2 block">Emerging & Innovating</span>
                          <h2 className="font-bold text-3xl sm:text-4xl text-dark mb-8">{t(`welcome.aboutDesc1`)}</h2>
                          <p className="text-base text-body-color mb-8">{t(`welcome.aboutDesc2`)}</p>
                          <p className="text-base text-body-color mb-8">{t(`welcome.aboutDesc3`)}</p>
                          <p className="text-base text-body-color mb-12">{t(`welcome.aboutDesc4`)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col px-8 py-4 mx-auto space-y-12 lg:mb-14 max-w-7xl xl:px-12">
            <div className="relative">
              <h1 className="title-font font-extralight text-gray-600 text-3xl text-center sm:text-3xl md:text-3xl ">
                {t(`welcome.ourTeam`)}
              </h1>
            </div>
          </div>

          <div className="w-full flex justify-center items-center">
            <div className="max-w-7xl">
              <div className="grid grid-cols-1 lg:grid lg:grid-cols-2">
                <div className="col-span-2">
                  <div className="grid grid-cols-2 lg:grid lg:grid-cols-2 ">
                    <div className="">
                      <div className="container mx-auto">
                        <div className="flex flex-wrap flex-row justify-center">
                          <div className="flex-shrink max-w-full px-4 w-3/3 sm:w-1/2 md:w-8/12 lg:w-2/4 xl:px-6">
                            <div
                              className="relative overflow-hidden bg-white mb-12 hover-grayscale-0 wow fadeInUp"
                              data-wow-duration="1s"
                              style={{
                                visibility: 'visible',
                                animationDuration: '1s',
                                animationDelay: '0.1s',
                                animationName: 'fadeInUp',
                              }}
                            >
                              <div className="relative overflow-hidden px-6">
                                <img
                                  src="/wow.png"
                                  className="max-w-full h-auto mx-auto rounded-full bg-gray-50 "
                                  alt="title1"
                                />
                              </div>
                              <div className="pt-4 text-center">
                                <p className="text-lg leading-normal font-bold mb-1">{t(`welcome.magu`)}</p>
                                <p className="text-gray-500 leading-relaxed font-light">{t(`welcome.ceo`)}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="container mx-auto">
                        <div className="flex flex-wrap flex-row justify-center">
                          <div className="flex-shrink max-w-full px-4 w-3/3 sm:w-1/2 md:w-8/12 lg:w-2/4 xl:px-6">
                            <div
                              className="relative overflow-hidden bg-white mb-12 hover-grayscale-0 wow fadeInUp"
                              data-wow-duration="1s"
                              style={{
                                visibility: 'visible',
                                animationDuration: '1s',
                                animationDelay: '0.1s',
                                animationName: 'fadeInUp',
                              }}
                            >
                              <div className="relative overflow-hidden px-6">
                                <img
                                  src="Coo.png"
                                  className="max-w-full h-auto mx-auto rounded-full bg-gray-50 "
                                  alt="title1"
                                />
                              </div>
                              <div className="pt-4 text-center">
                                <p className="text-lg leading-normal font-bold mb-1">{t(`welcome.erka`)}</p>
                                <p className="text-gray-500 leading-relaxed font-light">{t(`welcome.coo`)}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="container mx-auto">
                        <div className="flex flex-wrap flex-row  justify-center">
                          <div className="flex-shrink max-w-full px-4 w-3/3 sm:w-1/2 md:w-8/12 lg:w-2/4 xl:px-6">
                            <div
                              className="relative overflow-hidden bg-white mb-12 hover-grayscale-0 wow fadeInUp"
                              data-wow-duration="1s"
                              style={{
                                visibility: 'visible',
                                animationDuration: '1s',
                                animationDelay: '0.1s',
                                animationName: 'fadeInUp',
                              }}
                            >
                              <div className="relative overflow-hidden px-6">
                                <img
                                  src="/Ceoe.png"
                                  className="max-w-full h-auto mx-auto rounded-full bg-gray-50 "
                                  alt="title1"
                                />
                              </div>
                              <div className="pt-6 text-center">
                                <p className="text-lg leading-normal font-bold mb-1">{t(`welcome.mark`)}</p>
                                <p className="text-gray-500 leading-relaxed font-light">
                                  {t(`welcome.cee`)}
                                  <br />
                                  {t(`welcome.cee2`)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="container mx-auto">
                        <div className="flex flex-wrap flex-row  justify-center">
                          <div className="flex-shrink max-w-full px-4 w-3/3 sm:w-1/2 md:w-8/12 lg:w-2/4 xl:px-6">
                            <div
                              className="relative overflow-hidden bg-white mb-12 hover-grayscale-0 wow fadeInUp"
                              data-wow-duration="1s"
                              style={{
                                visibility: 'visible',
                                animationDuration: '1s',
                                animationDelay: '0.1s',
                                animationName: 'fadeInUp',
                              }}
                            >
                              <div className="relative overflow-hidden px-6">
                                <img
                                  src="Ven10.jpeg"
                                  className="max-w-full h-auto mx-auto rounded-full bg-gray-50 "
                                  alt="title1"
                                />
                              </div>
                              <div className="pt-6 text-center">
                                <p className="text-lg leading-normal font-bold mb-1">{t(`welcome.banhin`)}</p>
                                <p className="text-gray-500 leading-relaxed font-light">{t(`welcome.cto`)}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-1 bg-white">
            <Footer />
          </div>
        </main>
      </div>
    </>
  );
}
